.square-icon-container {
  border: 1px solid var(--green);
  box-shadow: 4px 4px 0 var(--green);
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .square-icon {
    font-size: 22px;
  }
}
