.activity-section {
  padding: 40px 0px;

  @media (max-width: 767px) {
    padding: 20px 30px;
  }
  .activity-section-container {
    text-align: center;
    max-width: 698px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .activity-section-title-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;
      margin: 0 auto;
    }
    .activity-section-item-title-container {
      @media (min-width: 576px) {
        height: 60px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .activity-section-title {
      font-family: "Unbounded", cursive;
      font-weight: 900;
      font-size: 20px;
    }

    .activity-section-green {
      color: var(--green);
    }
    .activity-section-yellow {
      color: var(--yellow);
    }
    .activity-section-blue {
      color: var(--blue);
    }
    .activity-section-item {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      .activity-section-item-title {
        font-family: "Unbounded", cursive;
        font-weight: 900;
        font-size: 16px;
      }
    }
  }
}
