body {
  margin: 0;
  font-family: "Nunito", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--text);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--text);
  margin: 0px;
}

h1 {
  font-family: "Unbounded", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 52px;
  @media (max-width: 576px) {
    font-size: 38px;
  }
  span {
    color: var(--yellow);
  }
}
h2 {
  font-weight: 900;
  font-size: 26px;
}
h3 {
  font-weight: 500;
  font-size: 22px;
}
h4 {
  font-weight: 500;
  font-size: 18px;
}

h5 {
  font-weight: 700;
  font-size: 16px;
  color: var(--blue);
}

h6 {
  font-weight: 400;
  font-size: 12px;
}

p {
  font-weight: 500;
  font-size: 15px;
}

a {
  font-weight: 300;
  font-size: 12px;
  color: var(--blue);
  text-decoration: none;
  &:hover {
    color: var(--green);
  }
}
