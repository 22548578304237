.education-section {
  text-align: center;
  max-width: 768px;
  margin: 40px auto;
  @media (max-width: 767px) {
    padding: 0px 20px;
  }

  .education-list {
    list-style-type: none;
    padding: 20px 0px;
  }
}
