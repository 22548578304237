.skills-section {
  text-align: center;
  max-width: 768px;
  margin: 40px auto;
  @media (max-width: 767px) {
    padding: 0px 20px;
  }

  .skills-lines {
    padding: 40px 0px;
  }

  // .skills-line {
  //   display: flex;
  //   flex-direction: row;
  //   gap: 26px;
  //   align-items: center;
  //   justify-content: center;
  // }
}
