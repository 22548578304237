.skill-cell {
  background-color: var(--card);
  border-radius: 24px;
  font-weight: 700;
  padding: 0px 14px 0px 0px;
  display: inline-flex;
  align-items: center;
  margin: 9px;

  .skill-cell-icon {
    margin: 6px;
    width: 34px;
    height: 34px;
    border-radius: 18px;
    background-color: var(--blue);
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .skill-cell-text {
    margin: 6px;
    display: inline-block;
    margin: 0px;
    padding: 0px;
  }
  .skill-cell-icon-img {
    filter: brightness(0) saturate(100%) invert(98%) sepia(0%) saturate(1804%)
      hue-rotate(325deg) brightness(105%) contrast(101%);
  }
}
