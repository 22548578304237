.work-section {
  text-align: center;
  max-width: 768px;
  margin: 40px auto;
  @media (max-width: 767px) {
    padding: 0px 20px;
  }

  .work-list {
    list-style-type: none;
    padding: 20px 0px;
  }

  .work-section-cell-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    .work-section-cell {
      display: flex;
      gap: 4px;
      flex-direction: column;
      .work-section-date {
        font-family: "Unbounded", cursive;
        font-weight: 700;
        font-size: 12px;
        color: var(--green);
      }
    }
  }
}
