.circle-text-container {
  cursor: pointer;
}

.circle-text {
  position: relative;
  // width: 100%;
  // padding-bottom: 100%;
  overflow: hidden;
  font-family: "Unbounded";
  user-select: none;
  z-index: -1;
  // cursor: zoom-in;

  width: 236px;
  padding-bottom: 300px;
  padding-right: 300px;
  margin-top: -46px;
}

.circle-text-arrow {
  position: relative;
  z-index: 1;
  padding-bottom: 150px;
  margin-top: -160px;
}

// #circle text { font-family: 'Helvetica Neue', Arial; font-size: 16px; font-weight: bold; }

.circle-text svg {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -ms-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: var(--revolutionDuration);
  -moz-animation-duration: var(--revolutionDuration);
  -ms-animation-duration: var(--revolutionDuration);
  -o-animation-duration: var(--revolutionDuration);
  animation-duration: var(--revolutionDuration);
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  cursor: pointer;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0);
  }
}
@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(360deg);
  }
  to {
    -moz-transform: rotate(0);
  }
}
@-ms-keyframes rotate {
  from {
    -ms-transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0);
  }
}
@-o-keyframes rotate {
  from {
    -o-transform: rotate(360deg);
  }
  to {
    -o-transform: rotate(0);
  }
}
@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
