.topbar {
  height: 62px;
  position: fixed;
  margin-bottom: 0;
  z-index: 10;
  border: 0;
  top: 20px;
  left: 0px;
  right: 0px;
  left: 40px;
  right: 40px;
  border-radius: 0;
  background: var(--background-nav);
  border: 1px var(--text) solid;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  box-shadow: 4px 4px 0 var(--text);

  @media screen and (max-width: 599px) {
    left: 20px;
    right: 20px;
  }

  // box-shadow: 0 2px 4px rgb(0 0 0 / 4%);

  .topbar-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // max-width: 768px;
    margin: 12px auto;
    padding: 0 12px;

    .topbar-links {
      display: flex;
      flex-direction: row;
      gap: 26px;

      @media screen and (max-width: 599px) {
        display: none;
      }

      .topbar-link-container {
        align-self: center;
        padding: 6px 10px;
        cursor: pointer;
        &:hover {
          padding: 5px 9px;
          border: 1px var(--text) solid;
        }
      }
      .topbar-link-container--active {
        padding: 5px 9px;
        border: 1px var(--text) solid;
      }
    }

    .topbar-buttons-mobile {
      display: flex;
      flex-direction: row;
      gap: 8px;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: 600px) {
        display: none;
      }
    }

    .topbar-dropdown-indicator {
      font-size: 20px;
      padding: 0px 10px;
      cursor: pointer;
    }
  }

  .topbar-links-mobile {
    display: flex;
    flex-direction: column;
  }

  .topbar-link {
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;

    @media screen and (max-width: 599px) {
      font-size: 16px;
      padding: 10px 14px;
      cursor: pointer;
      &:hover {
        font-weight: 900;
      }
    }
  }
  .dropdown-container {
    margin-top: 30px;
    background: var(--background);
    border: 1px var(--text) solid;
    box-shadow: 4px 4px 0 var(--text);
    z-index: 6;
  }
}
