.dark-mode-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: var(--blue-a10);
  cursor: pointer;
  transition: ease-in-out 0.2s;

  &:hover {
    background-color: var(--text);
  }
  &-sun {
    color: var(--yellow);
    &:hover {
      color: var(--dark_blue);
    }
  }
  &-moon {
    color: var(--dark_blue);
    &:hover {
      color: var(--yellow);
    }
  }
}
