.tag {
  padding: 4px 10px;
  background-color: var(--background);
  border-radius: 5px;
  .tag-text {
    color: var(--green);
    font-weight: 800;
    font-size: 12px;
  }
}
