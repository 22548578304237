.footer {
  text-align: center;
  max-width: 992px;
  padding: 20px 0px;
  @media (max-width: 992px) {
    padding: 16px 20px 0px 20px;
    margin: 20px;
  }
  border-top: 1px solid var(--border);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px auto;
  @media (max-width: 576px) {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  p {
    font-style: italic;
    font-weight: 800;
  }
  .footer-icons {
    display: flex;
    gap: 12px;
    font-size: 20px;

    .footer-icon {
      height: 26px;
      width: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      border-radius: 4px;
      transition: ease-in-out 0.25s;
      cursor: pointer;
    }

    @mixin footer-icon-theme($color) {
      &:hover {
        background-color: $color;
        color: white;
      }
    }

    .footer-icon-twitter {
      @include footer-icon-theme(#1da1f2);
    }
    .footer-icon-linkedin {
      @include footer-icon-theme(#0a66c2);
    }
    .footer-icon-github {
      @include footer-icon-theme(#00002a);
    }
    .footer-icon-dribbble {
      @include footer-icon-theme(#ea4c89);
    }
  }
}
