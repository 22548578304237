.ui-marquee {
  width: 100%;
  display: flex;
  overflow: hidden;
  gap: var(--gap);

  .ui-marquee-group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll var(--duration) linear infinite;
    animation-play-state: var(--play-state);
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }
}
