.separator-icon {
  color: var(--yellow);
  font-size: 12px;
}

.about-me-item-title {
  font-size: 16px;
  font-family: "Unbounded";
  font-weight: 900;
  color: var(--text);
  display: flex;
  gap: 8px;
}

.about-me-item-subtitle {
  font-size: 16px;
  font-family: "Nunito";
  font-weight: 700;
  font-style: italic;
  color: var(--subtitle);
}
