.main-section {
  padding: 80px 0px 0px 0px;
  @media (max-width: 767px) {
    padding: 80px 20px 0px 20px;
  }
  max-width: 642px;
  margin: 0 auto;
  .main-section-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .main-section-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
