.ui-button {
  display: inline-block;
  background: var(--blue);
  font-family: "Nunito";
  font-weight: 700;
  font-size: 14px;
  transition: 0.5s;
  border: 1px solid var(--blue);
  color: var(--white);
  padding: 8px 12px;
  align-items: center;
  cursor: pointer;
  top: 0;
  right: 0;
  transition: all 0.15s linear 0s;
  position: relative;
  display: inline-block;
  box-shadow: 4px 4px 0 var(--dark_blue);
  text-decoration: none;

  &:hover {
    top: 1px;
    right: -1px;
    box-shadow: 2px 2px 0 var(--dark_blue);
    &::after {
      bottom: -2.4px;
      left: -0.4px;
      width: 2.8px;
      height: 2.8px;
    }
    &::before {
      top: -0.4px;
      right: -2.4px;
      width: 2.8px;
      height: 2.8px;
    }
  }

  &::after {
    transition: all 0.15s linear 0s;
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0.5px;
    width: 6px;
    height: 6px;
    background-color: var(--red);
    transform: rotate(45deg);
    z-index: -1;
  }

  &::before {
    transition: all 0.15s linear 0s;
    content: "";
    position: absolute;
    top: 0.2px;
    right: -3.8px;
    width: 5.6px;
    height: 5.6px;
    background-color: var(--red);
    transform: rotate(45deg);
    z-index: -1;
  }

  &:active {
    top: 2px;
    right: -2px;
    box-shadow: none !important;
    &::after {
      bottom: 1px;
      left: 1px;
    }
    &::before {
      top: 1px;
      right: 1px;
    }
  }

  // &:hover:enabled {
  //   opacity: 0.9;
  // }

  // &:active:enabled {
  //   opacity: 0.7;
  // }

  // &:disabled {
  //   opacity: 0.4;
  //   cursor: not-allowed;
  // }
}

.ui-button-full {
  display: block;
  width: 100%;
}

@mixin ui-button-theme($color) {
  border: 1px solid var($color);
  background: var(--card);
  color: var($color);
  box-shadow: 4px 4px 0 var($color);
  &:hover {
    color: var($color);
    box-shadow: 2px 2px 0 var($color);
  }
  &::before {
    background-color: var($color);
  }
  &::after {
    background-color: var($color);
  }
}

@mixin ui-button-theme-outline($color) {
  background: none;
  color: var($color);
  border: 1px solid var($color);
}

.ui-button-green {
  @include ui-button-theme(--green);
}
.ui-button-yellow {
  @include ui-button-theme(--yellow);
}
.ui-button-blue {
  @include ui-button-theme(--blue);
}
.ui-button-dark_blue {
  @include ui-button-theme(--dark_blue);
}
.ui-button-red {
  @include ui-button-theme(--red);
}

.ui-button-green-outline {
  @include ui-button-theme-outline(--green);
}
.ui-button-yellow-outline {
  @include ui-button-theme-outline(--yellow);
}
.ui-button-blue-outline {
  @include ui-button-theme-outline(--blue);
}
.ui-button-dark_blue-outline {
  @include ui-button-theme-outline(--dark_blue);
}
.ui-button-red-outline {
  @include ui-button-theme-outline(--red);
}
