.experience-cell {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
  padding: 20px 0px;
  .experience-cell-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-family: "Unbounded";
      font-weight: 900;
      font-size: 20px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }

    .experience-cell-date {
      font-family: "Unbounded";
      font-size: 20px;
      text-align: right;
      @media (max-width: 767px) {
        text-align: left;
        font-weight: 700;
        font-size: 18px;
      }
    }

    .experience-cell-subtitle-container {
      display: flex;
      gap: 20px;
      @media (max-width: 767px) {
        flex-direction: column;
        gap: 6px;
      }
      .experience-icon-text {
        display: flex;
        gap: 10px;
        .experience-text {
          color: var(--subtitle);
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
  }
  .experience-cell-container {
    .experience-cell-banner {
      border-radius: 8px;
      background-color: var(--blue);
      height: 140px;
    }
  }

  .experience-cell-keywords {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;
  }
}
.experience-cell + .experience-cell {
  border-top: 1px solid var(--card);
}
